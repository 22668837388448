<template>
  <div>
    <BaseNav></BaseNav>
    <!-- Page content -->
    <div class="page-content">
      <!-- Main content -->
      <div class="content-wrapper">
        <!-- Content area -->
        <div class="content d-flex justify-content-center align-items-center">
          <base-loading v-if="loading"></base-loading>
        </div>
        <!-- /content area -->
      </div>
      <!-- /main content -->
    </div>
    <!-- /page content -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState(["auth/is_logged_in", "auth/user"]),
  },
  data() {
    return {
      loading: true,
      loginForm: new this.$Form({
        token: "",
      }),
    };
  },
  methods: {
    ...mapActions("auth", ["doLogin", "doGetAuthUser"]),
    silentLogin() {
      this.loading = true;
      let token = this.$route.query.token;
      console.log(this.$route.query.token);

      // clear localstorage token
      localStorage.removeItem("token");

      if (!token || token === "") {
        this.$router.push({ name: "Login" });
        this.loading = false;
      } else {
        this.loginForm.token = token;
        this.loginForm
          .post(`${process.env.VUE_APP_API_BASE_URL}/Auth/SilentLogin/`)
          .then((res) => {
            localStorage.setItem(
              "token",
              `Bearer ${res.data.Data.access_token}`
            );
            if (this.$route.query.redirectAfterAuth) {
              document.location.href = this.$route.query.redirectAfterAuth;
            } else {
              document.location.href = `/${process.env.VUE_APP_INSTALL_DIR}/home/`;
            }
          })
          .catch((err) => {
            console.log("silentloginerror", err);
            this.$notify({
              title: "Error",
              text: err.response.data
                ? err.response.data.Message
                : "Error while silent login in",
              style: "danger",
            });
            this.$router.push({ name: "Login" });
          })
          .finally(() => {
            this.loading = false;
          });
      }
      this.loading = false;
    },
  },
  mounted() {
    this.silentLogin();
  },
};
</script>
